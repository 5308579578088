<template>
  <a-layout-content>
    <div class="nav">
      <Breadcrumb>
        <BreadcrumbItem>发货</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <div class="content-container">
      <a-form class="advanced-search-form" :form="form">
        <div class="card__container">
          <div class="card__title">1、填写物流信息</div>
          <a-row>
            <a-col :span="8">
              <a-form-item label="物流公司">
                <a-select
                  v-decorator="['logisticsName']"
                  allow-clear
                  show-search
                  :options="logisticsList"
                  @search="onSearchLogistics"
                ></a-select>
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="联系电话">
                <a-input
                  v-decorator="[
                    'logisticsTel',
                    {
                      rules: [{ validator: mobileCheck.bind(this) }]
                    }
                  ]"
                />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="物流单号">
                <a-input v-decorator="['logisticsSn']" />
              </a-form-item>
            </a-col>
          </a-row>
        </div>
        <div class="card__container">
          <div class="card__title">2、收货信息</div>
          <a-row>
            <a-col :span="8">
              <a-form-item label="收货人姓名">
                <a-input
                  v-decorator="[
                    'consigneeName',
                    {
                      rules: [{ required: true, message: '请输入收货人姓名' }]
                    }
                  ]"
                />
              </a-form-item>
            </a-col>
            <a-col :span="8">
              <a-form-item label="收货人电话">
                <a-input
                  v-decorator="[
                    'consigneeTel',
                    {
                      rules: [
                        { required: true, message: '请输入收货人电话' },
                        { validator: mobileCheck.bind(this) }
                      ]
                    }
                  ]"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="8">
              <a-form-item label="收货人地区">
                <a-cascader
                  v-decorator="[
                    'consigneeCountyCode',
                    { rules: [{ required: true, message: '请选择收货人地区' }] }
                  ]"
                  :options="areaData"
                  :field-names="fieldNames"
                  placeholder="请选择"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="详细地址">
                <a-input
                  v-decorator="[
                    'consigneeDetailAddress',
                    { rules: [{ required: true, message: '请输入详细地址' }] }
                  ]"
                  allow-clear
                />
              </a-form-item>
            </a-col>
          </a-row>
        </div>
      </a-form>
      <div class="card__container">
        <div class="card__title between">
          <div>3、商品信息</div>
          <div class="operation">
            <a @click="onEditDeliver">编辑发货信息</a>
          </div>
        </div>
        <a-table
          :columns="tblColumns"
          :data-source="tblData"
          :pagination="false"
          :loading="loading"
          row-key="skuId"
        >
          <div
            slot="skuImages"
            v-viewer
            slot-scope="text, record"
            class="img__container"
          >
            <img
              :src="imageBaseUrl + record.skuPic"
              :data-source="imageBaseUrl + record.skuPic"
              alt="商品图"
            />
          </div>
          <template slot="hasSn" slot-scope="text">
            {{ text ? "是" : "否" }}
          </template>
          <template slot="quantityShipped" slot-scope="text, record">
            {{ record.totalQuantity - record.quantity }}
          </template>
        </a-table>
        <div class="total__price">订单总金额：¥{{ totalPrice }}</div>
      </div>
      <div class="card__container bottom">
        <div class="card__title">4、确认发货信息</div>
        <div class="deliver__info">
          我的发货信息：
          <span>{{ senderAddress }}</span>
          <a-icon
            type="edit"
            :style="{ fontSize: '16px' }"
            @click="onEditMineDeliverInfo"
          />
        </div>
      </div>
    </div>
    <div class="operation__container">
      <a-space>
        <a-button @click="onCancel">取消</a-button>
        <a-button type="primary" @click="onDeliver">确认发货</a-button>
      </a-space>
    </div>

    <!-- 发货地址选择弹窗 -->
    <a-modal
      title="请选择合适的地址"
      :visible="sendVisible"
      @cancel="sendVisible = false"
    >
      <div v-if="addressList.length > 0">
        <a-radio-group v-model="defaultSelectAddressId">
          <a-radio
            v-for="item in addressList"
            :key="item.addressId"
            :value="item.addressId"
            class="radio__item"
          >
            {{ item.addressItem }}
          </a-radio>
        </a-radio-group>
      </div>
      <a-empty v-else description="暂无地址，请去新增" />
      <template slot="footer">
        <a-button @click="onEnterAddress">
          地址库管理
        </a-button>
        <a-button type="primary" @click="onSureAddress">
          确定
        </a-button>
      </template>
    </a-modal>
  </a-layout-content>
</template>

<script>
import BreadcrumbItem from "@/components/BreadcrumbItem";
import Breadcrumb from "@/components/Breadcrumb";
import { onSearchLogistics } from "@/services/FactoryService";
import { loadArea } from "@/services/DealerService";
import { fetchAddressList } from "@/services/OrderService";
import { pageDetailMixins } from "@/components/pageDetailMixin";
import {
  fetchOrderDetail,
  fetchUnDeliverSkuInfo
} from "@/services/OrderManageService";
import { imageBaseUrl } from "@/services/HttpService";
import { factoryDeliverGoods } from "@/services/CommodityService";

const tblColumns = [
  {
    title: "商品图",
    dataIndex: "skuImages",
    scopedSlots: { customRender: "skuImages" },
    width: "15%"
  },
  {
    title: "商品编码",
    dataIndex: "skuCode",
    width: "15%"
  },
  {
    title: "商品名称",
    dataIndex: "skuName",
    width: "20%"
  },
  {
    title: "商品单价（元）",
    dataIndex: "skuFactoryPrice",
    width: "10%"
  },
  {
    title: "是否有序列号",
    dataIndex: "hasSn",
    scopedSlots: { customRender: "hasSn" },
    width: "10%"
  },
  {
    title: "已发货数量",
    dataIndex: "quantityShipped",
    scopedSlots: { customRender: "quantityShipped" },
    width: "10%"
  },
  {
    title: "购买数量",
    dataIndex: "totalQuantity",
    width: "10%"
  },
  {
    title: "本次发货数量",
    dataIndex: "num",
    width: "10%"
  }
];

export default {
  name: "PublicDeliver",
  components: { Breadcrumb, BreadcrumbItem },
  mixins: [pageDetailMixins],
  data() {
    return {
      form: this.$form.createForm(this),
      logisticsList: [],
      areaData: [],
      fieldNames: {
        label: "areaName",
        value: "areaCode",
        children: "subAreas"
      },
      senderAddress: "",
      sendVisible: false,
      addressList: [],
      defaultSelectAddressId: "",
      tblColumns,
      tblData: [],
      loading: false,
      commodityForm: this.$form.createForm(this),
      hasChange: false,
      orderSn: this.$route.params.orderSn,
      type: this.$route.params.type,
      imageBaseUrl,
      totalPrice: null
    };
  },
  activated() {
    this.loadDeliverInfo();
    this.onLoadAddressList();
  },
  mounted() {
    this.loadDetail();
    this.loadAreaData();
    this.loadUnDeliverInfo();
  },
  methods: {
    // 查询物流公司
    onSearchLogistics(value) {
      const params = {
        factoryId: localStorage.getItem("factoryId"),
        keyword: value
      };
      onSearchLogistics(params).then(resp => {
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data;
          this.searchList = data;
          if (data && data.length > 0) {
            this.logisticsList = [];
            data.forEach(item => {
              const obj = {
                value: item.companyName,
                label: item.companyName
              };
              this.logisticsList.push(obj);
            });
          } else {
            this.logisticsList = [];
            this.logisticsList.push({ label: value, value: value });
          }
        }
      });
    },
    // 加载详情
    loadDetail() {
      const params = {
        orderSn: this.orderSn,
        type: "deliver"
      };
      fetchOrderDetail(params).then(resp => {
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data;
          if (
            data.orderTypeValue === "即时订单" ||
            data.orderTypeValue === "远期订单" ||
            data.orderTypeValue === "租赁联合运营"
          ) {
            this.form.setFieldsValue({
              consigneeName: data.consigneeRealName,
              consigneeTel: data.consigneeTel,
              consigneeCountyCode: [
                data.consigneeProvinceCode,
                data.consigneeCityCode,
                data.consigneeCountyCode
              ],
              consigneeDetailAddress: data.consigneeDetailAddress
            });
          } else if (data.orderTypeValue === "换货订单") {
            this.form.setFieldsValue({
              consigneeName: data.purName,
              consigneeTel: data.purTel,
              consigneeCountyCode: [
                data.purProvinceCode,
                data.purCityCode,
                data.purCountyCode
              ],
              consigneeDetailAddress: data.purDetailAddress
            });
          }
        }
      });
    },
    // 检查手机号
    mobileCheck(rule, value, callbackFn) {
      const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (value && !reg.test(value)) {
        callbackFn("手机号码格式不正确");
        return;
      }
      callbackFn();
    },
    // 加载行政区数据
    loadAreaData() {
      this.areaData = [];
      loadArea().then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.areaData = resp.data.data;
        } else {
          this.$message.error(resp.data.errorMsg);
        }
      });
    },
    // 发货弹窗展示
    onEditMineDeliverInfo() {
      this.sendVisible = true;
    },
    // 获取当前原厂的发货地址列表
    onLoadAddressList() {
      const factoryId = localStorage.getItem("factoryId");
      fetchAddressList(factoryId).then(resp => {
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data;
          if (data.length > 0) {
            data.forEach(item => {
              if (
                item.senderProvinceName ||
                item.senderCityName ||
                item.senderCountyName
              )
                if (item.senderProvinceName === item.senderCityName) {
                  item.area = `${item.senderCityName} ${item.senderCountyName} ${item.detailAddress}`;
                } else {
                  item.area = `${item.senderProvinceName} ${item.senderCityName} ${item.senderCountyName} ${item.detailAddress}`;
                }
              item.addressItem = `${item.area}，${item.senderName}，${item.senderTel}`;
            });
          }
          this.addressList = data;
          if (this.addressList.length > 0) {
            this.defaultSelectAddressId = this.addressList[0].addressId;
            this.senderAddress = this.addressList[0].addressItem;
          } else {
            this.defaultSelectAddressId = "";
            this.senderAddress = "";
          }
        }
      });
    },
    // 进入发货信息编辑页面
    onEnterAddress() {
      this.$router.push({
        name: "ShippingAddress",
        params: { procureOrderSn: this.orderSn }
      });
      this.sendVisible = false;
    },
    // 选择发货地址信息
    onSureAddress() {
      if (this.defaultSelectAddressId === "") {
        this.$message.info("请选择一个发货地址");
      } else {
        const index = this.addressList.findIndex(
          item => item.addressId === this.defaultSelectAddressId
        );
        this.senderAddress = this.addressList[index].addressItem;
        this.sendVisible = false;
      }
    },
    // 查询未发货信息
    loadUnDeliverInfo() {
      this.loading = true;
      let bizType = "";
      if (this.type === "EXCHANGE") {
        bizType = "producer";
      }
      fetchUnDeliverSkuInfo(this.orderSn, this.type, bizType)
        .then(resp => {
          this.loading = false;
          if (resp.data.code === "SUCCESS") {
            this.tblData = resp.data.data;
            let skuCodeList = [];
            let totalPrice = 0;
            this.tblData.forEach(item => {
              totalPrice =
                totalPrice + Number(item.skuFactoryPrice) * item.totalQuantity;
              const params = {
                skuCode: item.skuCode,
                purchaseQuantity: item.totalQuantity,
                quantityShipped: item.totalQuantity - item.quantity
              };
              skuCodeList.push(params);
            });
            this.totalPrice = totalPrice.toFixed(2);
            sessionStorage.setItem("skuCodeList", JSON.stringify(skuCodeList));
            this.loadDeliverInfo();
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 加载发货信息
    loadDeliverInfo() {
      if (sessionStorage.getItem("deliverList")) {
        const deliverList = JSON.parse(sessionStorage.getItem("deliverList"));
        this.tblData.forEach(item => {
          const list = deliverList.filter(
            i => i.productNumber === item.skuCode
          );
          let num = 0;
          if (list && list.length > 0) {
            list.forEach(ele => {
              num = num + ele.quantity;
            });
          }
          item.num = num;
        });
        this.tblData = [...this.tblData];
      } else {
        this.tblData.forEach(item => {
          item.num = 0;
        });
        this.tblData = [...this.tblData];
      }
    },
    // 取消
    onCancel() {
      this.$router.push({
        name: "ToDeliverOrderDetail",
        params: { orderSn: this.orderSn }
      });
    },
    // 发货
    onDeliver() {
      this.form.validateFields((err, values) => {
        if (!err) {
          let detailFormList = [];
          if (sessionStorage.getItem("deliverList")) {
            const deliverList = JSON.parse(
              sessionStorage.getItem("deliverList")
            );
            if (deliverList.length > 0) {
              deliverList.forEach(item => {
                let subList = [];
                if (item.subGoodsList && item.subGoodsList.length > 0) {
                  item.subGoodsList.forEach(ele => {
                    subList.push({
                      skuId: ele.skuId,
                      goodsSn: ele.seriesNumber,
                      quantity: ele.quantity,
                      batchNo: ele.batchNumber
                    });
                  });
                }
                const params = {
                  skuId: item.skuId,
                  goodsSn: item.seriesNumber,
                  quantity: item.quantity,
                  batchNo: item.batchNumber,
                  partsList: subList
                };
                detailFormList.push(params);
              });
            } else {
              this.$message.info("请编辑发货信息");
              return;
            }
          }
          if (!this.senderAddress) {
            this.$message.info("请选择发货地址");
            return;
          }
          var that = this;
          that.$store.state.hasChange = true;
          this.$confirm({
            title: "请确认是否发货?",
            okText: "确定",
            okType: "info",
            cancelText: "取消",
            onOk() {
              let item = that.addressList.find(
                item => item.addressId === that.defaultSelectAddressId
              );
              values.consigneeCountyCode = values.consigneeCountyCode[2];
              values.orderSn = that.orderSn;
              values.senderTel = item.senderTel;
              values.senderCountryCode = item.countyCode;
              values.senderDetailAddress = item.area;
              values.detailFormList = detailFormList;
              factoryDeliverGoods(values).then(resp => {
                if (resp.data.code === "SUCCESS") {
                  that.$message.success("发货成功");
                  sessionStorage.removeItem("deliverList");
                  if (that.type === "EXCHANGE") {
                    that.$router.push({
                      name: "ToReceiverOrderDetail",
                      params: { orderSn: that.orderSn, type: "deliver" }
                    });
                  } else {
                    that.$router.push({
                      name: "ToDeliverOrderDetail",
                      params: { orderSn: that.orderSn }
                    });
                  }
                }
              });
            }
          });
        }
      });
    },
    // 编辑发货信息
    onEditDeliver() {
      this.$router.push({
        name: "EditDeliverInfo",
        params: { orderSn: this.orderSn, type: this.type }
      });
    }
  },
  beforeRouteLeave(to, from, next) {
    if (
      to.name === "PublicDeliver" ||
      to.name === "EditDeliverInfo" ||
      to.name === "ShippingAddress"
    ) {
      next();
    } else {
      sessionStorage.removeItem("deliverList");
    }
    next();
  }
};
</script>

<style scoped>
.content-container {
  min-height: 100vh;
  background-color: transparent;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.card__container {
  box-sizing: border-box;
  padding: 20px;
  margin-top: 20px;
  background-color: white;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.24);
  display: flex;
  flex-direction: column;
}

.card__title {
  color: rgba(16, 16, 16, 0.8);
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
}

/deep/ .ant-form-item .ant-form-item-label {
  text-align: right;
  min-width: 100px;
}

/deep/ .ant-form-item {
  display: flex;
  margin-right: 10%;
}

/deep/ .advanced-search-form .ant-form-item {
  display: flex;
}

/deep/ .ant-form-item .ant-form-item-control-wrapper {
  flex: 1;
}

.icon--add {
  width: 24px;
  height: 24px;
}

.between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn--download {
  padding: 0;
}

.total__price {
  margin-top: 20px;
}

.radio__item {
  margin-bottom: 10px;
}

.operation__container {
  background-color: white;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 10px 20px;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
}

.bottom {
  margin-bottom: 80px;
}

.img__container {
  width: 100px;
  height: 100px;
}

.img__container img {
  width: 100px;
  object-fit: cover;
}
</style>
